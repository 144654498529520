<template>
  <footer class="footer">
    <div class="content has-text-centered">
      <p>
        {{ new Date().getFullYear() }} — <strong>KUB.PRO 22</strong>
      </p>
    </div>
  </footer>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  name: "FooterCentral",
  props: {
    msg: String
  },
  data: () => ({
    links: [
      'Home',
      'About',
    ],
  }),
  computed: {
    ...mapGetters('auth', {
      isAuthenticated: 'isAuthenticated',
      authUser: 'authUser',
      isTypeUser: 'isTypeUser',
      isVerification: 'isVerification'
    }),
  }

}
</script>

<style scoped>

</style>
