import {createApp} from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
require('./plugins/axios');

import App from './App.vue'
import router from "./router/index"
import store from '@/store'
import i18n from './plugins/i18n'
import vuetify from './plugins/vuetify'
//import IconsPlugin from './plugins/icons';

//scss
import './assets/scss/main.scss'

store.dispatch('auth/checkAuth');

//emitter
import mitt from 'mitt';
const emitter = mitt();

const app = createApp(App);
app.config.globalProperties.emitter = emitter;
//app.use(PrimeVue)
app.use(i18n)
app.use(router)
app.use(store)
app.use(vuetify)
//app.use(IconsPlugin)
app.use(VueAxios, axios)
app.mount('#app')
