import store from "@/store";

function isNotAuthenticated(to, from, next) {
    if (!store.getters['auth/isAuthenticated']) {
        next()
        return
    }
    next('/')
}

export default [
    {
        path: "/",
        name: 'delivery.list',
        component: () => import('../components/order/DeliveryList'),
        meta: {requiresAuth: true, requiresVerification: false, layout: 'central'}
    },
   /* {
        path: "/about",
        name: 'central.about',
        component: () => import('../components/central/AboutCentral'),
        meta: {requiresAuth: false, requiresVerification: false, layout: 'central'}
    },*/
    {
        path: "/payment-list",
        name: 'report.payment.list',
        component: () => import('../components/report/PaymentList'),
        meta: {requiresAuth: true, requiresVerification: false, layout: 'central'}
    },{
        path: "/salary",
        name: 'report.salary.list',
        component: () => import('../components/report/SalaryList'),
        meta: {requiresAuth: true, requiresVerification: false, layout: 'central'}
    },{
        path: "/fuel",
        name: 'report.fuel.list',
        component: () => import('../components/report/FuelList'),
        meta: {requiresAuth: true, requiresVerification: false, layout: 'central'}
    },
    {
        path: "/login",
        name: 'central.login',
        component: () => import('../components/auth/LoginCentral'),
        meta: {requiresAuth: false, requiresVerification: false, layout: 'central'},
        beforeEnter: isNotAuthenticated
    },
   /* {
        path: "/register",
        name: 'central.register',
        component: () => import('../components/auth/RegisterCentral'),
        meta: {requiresAuth: false, requiresVerification: false, layout: 'central'},
        beforeEnter: isNotAuthenticated
    },
    {
        path: "/forgot-password",
        name: 'central.forgot_password',
        component: () => import('../components/auth/ForgotPasswordCentral'),
        meta: {requiresAuth: false, requiresVerification: false, layout: 'central'},
        beforeEnter: isNotAuthenticated
    },
    {
        path: '/password-reset/:token',
        name: 'central.reset_password',
        component: () => import('../components/auth/ResetPasswordCentral'),
        meta: {requiresAuth: false, requiresVerification: false, layout: 'central'},
        beforeEnter: isNotAuthenticated
    },*/
    {
        path: '/404',
        name: '404',
        component: () => import('../components/central/NotFound'),
    },
    {
        path: "/:catchAll(.*)",
        redirect: '/404',
    },
]

