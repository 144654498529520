<template>
  <div class="navbar-item">
    <InputSwitch trueValue="uk" falseValue="ru" v-model="$i18n.locale" />
    <label class="ml-2">{{ $t('menu_up.locales.title') }}</label>
  </div>
</template>

<script>

export default {
  name: 'SwitchLocale',
  mounted() {
    if (localStorage.getItem("locale")) {
      this.$i18n.locale = localStorage.getItem("locale");
    } else {
      localStorage.setItem("locale", this.$i18n.locale);
    }
  },
  watch: {
    "$i18n.locale": function () {
      localStorage.setItem("locale", this.$i18n.locale);
    }
  }
}
</script>

<style>

</style>
