import axios from "axios";
//import store from "@/store";

const state = {
    authUser: {},
    isAuthenticated: false,
    isVerification: false,
    isTypeUser: false, // 0 - customer // 1 - master
    userPermissions: [],
}

const getters = {
    authUser(state) {
        return state.authUser;
    },
    isAuthenticated(state) {
        return state.isAuthenticated;
    },
    isVerification(state) {
        return state.isVerification;
    },
    isTypeUser(state) {
        return state.isTypeUser;
    },
    userPermissions(state) {
        return state.userPermissions;
    },
}

const mutations = {
    authUser(state, payload) {
        if (payload.id) {
            state.authUser = payload
        }
    },
    isAuthenticated(state, payload) {
        state.isAuthenticated = payload
    },
    isVerification(state, payload) {
        state.isVerification = !!payload;
    },
    isTypeUser(state, payload) {
        state.isTypeUser = !!payload;
    },
    userPermissions(state, payload) {
        state.userPermissions = payload;
    },
}

const computed = {}

const actions = {
    checkAuth(context) {
        const X_XSRF_TOKEN = !!localStorage.getItem('X-XSRF-TOKEN')
        context.commit('isAuthenticated', X_XSRF_TOKEN)
        try {
            if (X_XSRF_TOKEN) {
                axios.post('/api/driver/info').then(r => {
                    if (r.status === 200) {
                        context.commit('authUser', r.data)
                        //context.commit('isVerification', r.data.data.verification)
                        //context.commit('isTypeUser', r.data.data.type)
                        //context.commit('userPermissions', r.data.data.role.permissions)
                    }
                })
            }
        } catch (error) {
            throw new Error(error)
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
    computed
}
