import {createStore} from "vuex";
import auth from './modules/auth.state'
import all from './modules/all.state'

export default createStore({
    namespaced: true,
    strict: true,
    state: {},
    mutations: {},
    actions: {},
    getters: {},
    computed: {},
    modules: {
        auth,
        all
    }
})
