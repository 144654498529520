<template>
  <v-app>
    <header-central></header-central>
    <router-view></router-view>
    <footer-central></footer-central>
  </v-app>
</template>

<script>
import FooterCentral from "@/components/layouts/central/FooterCentral";
import HeaderCentral from "@/components/layouts/central/HeaderCentral";
import {mapGetters} from "vuex";

export default {
  name: "CentralLayout",
  components: {HeaderCentral, FooterCentral},
  computed: {
    ...mapGetters('auth', {
      isAuthenticated: 'isAuthenticated',
      authUser: 'authUser',
      isTypeUser: 'isTypeUser',
      isVerification: 'isVerification'
    }),
  }
}
</script>

<style scoped>

</style>
