<template>
  <upp-menu-account></upp-menu-account>
</template>
<script>
import {mapGetters} from 'vuex'
import UppMenuAccount from "@/components/layouts/menu/UppMenuAccount";

export default {
  name: "HeaderCentral",
  components: {UppMenuAccount},
  props: {
    msg: String
  },
  data: () => ({}),
  computed: {
    ...mapGetters('auth', {
      isAuthenticated: 'isAuthenticated',
      authUser: 'authUser',
      isTypeUser: 'isTypeUser',
      isVerification: 'isVerification'
    }),
  }
}
</script>

<style scoped>

</style>
