const state = {
    API_URL: process.env.VUE_APP_API_URL,
}

const getters = {}

const mutations = {}

const computed = {}

const actions = {}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
    computed
}
