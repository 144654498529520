import axios from "axios";
import {error, success} from '../router/redirects'
//import store from "@/store";
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.withCredentials = true;
axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.baseURL = process.env.VUE_APP_API_URL //todo change in production
//axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('X-XSRF-TOKEN')

axios.interceptors.request.use((config) => {
  const isAuthenticated = localStorage.getItem('X-XSRF-TOKEN');
  //const isAuthenticated = store.getters['auth/isAuthenticated'];
  if (isAuthenticated) {
    if (!config.headers) {
      config.headers = {};
    }
    config.headers['Authorization'] = `Bearer ${isAuthenticated}`;
  }
  return config;
});
axios.interceptors.response.use(success, error);

window.axios = axios
