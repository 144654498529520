import {createRouter, createWebHistory} from "vue-router"

require("@/store")
import store from "@/store";

import routes from './routes'
/*
let routes = [].concat(
    central,
    account,
    master,
    customer
)*/

const router = createRouter({
    history: createWebHistory(),
    routes: routes
})

router.beforeResolve(async (to) => {
    const isAuthenticated = await store.getters['auth/isAuthenticated'];
    const isVerification = await store.getters['auth/isVerification']

    //check in Authentication User for route
    if (to.meta.requiresAuth && !isAuthenticated) {
        return {
            path: '/login',
            query: {redirect: to.fullPath},
        }
    }

    //check in Verification for route
    if (to.meta.requiresVerification && !isVerification) {
        return {
            path: '/account/verification',
            query: {redirect: to.fullPath},
        }
    }
});

export default router
