<template>
  <footer class="d-flex justify-center mb-3" v-if="isAuthenticated">
    {{ new Date().getFullYear() }} — <strong>KUB</strong>
    <v-avatar class="contact-fly">
      <v-icon icon="mdi-phone-in-talk-outline"></v-icon>
      <div class="contact-list">
        <a href="viber://chat?number=%2B380953894645"><v-icon icon="icon-viber"></v-icon></a>
        <a href="tg://resolve?domain=kub_logistic"><v-icon icon="icon-telegram"></v-icon></a>
        <a href="tel:+380953894645"><v-icon icon="mdi-phone-in-talk-outline"></v-icon></a>
      </div>
    </v-avatar>
  </footer>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  name: "FooterCentral",
  props: {
    msg: String
  },
  data: () => ({
    links: [
      'Home',
      'About',
    ],
  }),
  computed: {
    ...mapGetters('auth', {
      isAuthenticated: 'isAuthenticated',
      authUser: 'authUser',
      isTypeUser: 'isTypeUser',
      isVerification: 'isVerification'
    }),
  }

}
</script>

<style scoped>

</style>
