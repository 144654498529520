<template>
  <header-account></header-account>
  <div class="container is-fullhd">
    <div class="min_height_container">
      <router-view></router-view>
    </div>
  </div>
  <footer-account></footer-account>
</template>

<script>
import FooterAccount from "@/components/layouts/account/FooterAccount";
import HeaderAccount from "@/components/layouts/account/HeaderAccount";
import {mapGetters} from "vuex";

export default {
  name: "AccountLayout",
  components: {HeaderAccount, FooterAccount},
  computed: {
    ...mapGetters('auth', {
      isAuthenticated: 'isAuthenticated',
      authUser: 'authUser',
      isTypeUser: 'isTypeUser',
      isVerification: 'isVerification'
    }),
  }
}
</script>

<style scoped>

</style>
