<template>


  <nav class="navbar" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <router-link class="navbar-item" to="/">
        <span class="is-bold">KUB.PROfgfg</span>
      </router-link>
      <v-btn  color="success" @click="logout">Logout</v-btn>
      <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>

    <div id="navbarBasicExample" class="navbar-menu">
      <div class="navbar-start">
        <router-link class="navbar-item" to="/account/home">
          {{ $t("menu_up.account.home.title") }}
        </router-link>

        <router-link v-if="isTypeUser" class="navbar-item" to="/account/clients">
          {{ $t("menu_up.account.clients.title") }}
        </router-link>

        <router-link v-if="isTypeUser" class="navbar-item" to="/account/facilities">
          {{ $t("menu_up.account.facilities.title") }}
        </router-link>

        <router-link v-if="isTypeUser" class="navbar-item" to="/account/estimates">
          {{ $t("menu_up.account.estimates.title") }}
        </router-link>

        <router-link v-if="isTypeUser" class="navbar-item" to="/account/services">
          {{ $t("menu_up.account.services.title") }}
        </router-link>

        <router-link v-if="isTypeUser" class="navbar-item" to="/account/orders">
          {{ $t("menu_up.account.orders.title") }}
        </router-link>

      </div>

      <div class="navbar-end">
        <div class="navbar-item">
          <router-link class="navbar-item" to="/account/profile">
            <i class="pi pi-user p-text-secondary"></i>
          </router-link>

          <router-link class="navbar-item" to="/account/help">
            <i class="pi pi-question-circle p-text-secondary"></i>
          </router-link>

          <switch-locale></switch-locale>



        </div>
      </div>
    </div>
  </nav>
</template>

<script>
//import axios from "axios";
import store from "@/store";
import {mapGetters} from "vuex";
import SwitchLocale from "@/components/layouts/SwitchLocale";

export default {
  name: "UppMenuAccount",
  components: {SwitchLocale},
  data: () => ({
    items: [
      {
        label: 'New',
        icon: 'pi pi-fw pi-plus',
        items: [
          {
            label: 'Bookmark',
            icon: 'pi pi-fw pi-bookmark'
          },
          {
            label: 'Video',
            icon: 'pi pi-fw pi-video'
          }
        ]
      },
      {
        label: 'Delete',
        icon: 'pi pi-fw pi-trash'
      },
      {
        separator: true
      },
      {
        label: 'Export',
        icon: 'pi pi-fw pi-external-link'
      }
    ]
  }),
  computed: {
    ...mapGetters('auth', {
      isAuthenticated: 'isAuthenticated',
      authUser: 'authUser',
      isTypeUser: 'isTypeUser',
      isVerification: 'isVerification'
    }),
  },
  methods: {
    async logout() {
      store.dispatch('auth/logout');
      /*axios.post('/logout').then(r => {
        if (r.status) {
          store.commit('auth/isAuthenticated', false)
          store.commit('auth/authUser', {})
          localStorage.removeItem('X-XSRF-TOKEN')
          this.$router.push({name: 'central.login'})
        }
      })*/
    }
  }
}
</script>

<style scoped>

</style>
