import { createVuetify } from 'vuetify'
//import 'vuetify/dist/vuetify.min.css'
import 'vuetify/styles'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import '@mdi/font/css/materialdesignicons.css'
//import { mdi } from 'vuetify/iconsets/mdi-svg'
//import { mdiAccount } from '@mdi/js'

export default createVuetify({
    components,
    directives,
    theme: {
        themes: {
            light: {
                background: '#FF0000' // змінюємо колір фону на червоний
            }
        }
    },
    icons: {
        defaultSet: 'mdi',
        /*aliases: {
            ...aliases,
            account: mdiAccount,
        },*/
        /*sets: {
            mdi,
        },*/
    },
})