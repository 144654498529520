<template>
  <component :is="layout">
    <router-view/>
  </component>
</template>

<script>
import {mapGetters} from "vuex";
import CentralLayout from "@/components/layouts/CentralLayout";
import AccountLayout from "@/components/layouts/AccountLayout";

import {defineComponent} from 'vue';

export default defineComponent({
  name: 'App',
  components: {AccountLayout, CentralLayout},
  computed: {
    layout() {
      return (this.$route.meta.layout || 'central') + '-layout'
    },
    ...mapGetters('auth', {
      isAuthenticated: 'isAuthenticated',
      authUser: 'authUser',
    }),
  },
});
</script>
